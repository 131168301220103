import FilterInputTypes from "./FilterInputTypes";
import {InputFieldOptions} from "../../../utils/InputFieldOptions";
import Utils from "../../../utils/Utils";

export default {
  name: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "name",
    required: false,
    label: `Name`,
    placeholder: `Enter name`,
  },
  user_id: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "user_id",
    required: false,
    label: `Email`,
    placeholder: `Enter email`,
  },
  incident_event_type_id: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "incident_event_type_id",
    required: false,
    label: `Incident Event Type`,
    placeholder: `Select`,
    options: []
  },

  id: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "id",
    required: false,
    label: `ID`,
    placeholder: `Enter id`,
  },
  title: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "title",
    required: false,
    label: `Title`,
    placeholder: `Enter title`,
  },
  email: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "email",
    required: false,
    label: `Email`,
    placeholder: `Enter email`,
  },
  mobile: {
    type: FilterInputTypes.TEXT_INPUT,
    name: "mobile",
    required: false,
    label: `Mobile`,
    placeholder: `Enter mobile`,
  },
  status: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "status",
    required: false,
    label: `Status`,
    placeholder: `Select Status`,
    options: Utils.prepareDropdownOptions(InputFieldOptions.status)
  },
  year: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "year",
    required: false,
    label: `Year`,
    placeholder: `Select Year`,
    options: Utils.prepareDropdownOptions(InputFieldOptions.year)
  },
  timeline: {
    type: FilterInputTypes.SELECT_INPUT,
    name: "time",
    required: false,
    label: `Timeline`,
    placeholder: `Select timeline`,
    options: []
  },

};