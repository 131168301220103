import React from "react";
import BlocBuilder from "../../BlocBuilder";
import SettingsBloc from "../../bloc/SettingsBloc";
import AuthBloc from "../../bloc/AuthBloc";
import {Form} from "antd";
import {Colors} from "../../css/Colors";
import {TextX} from "../shared/TextX";
import {InputText} from "../shared/FormComponent/InputText";
import {Box} from "../shared/Box";
import {ButtonX} from "../shared/ButtonX";
import {InputFields} from "../../utils/InputFields";
import CrudBloc from "../../bloc/CrudBloc";
import {getRouteList} from "../../utils/RouterUtils";
import {InputFieldOptions} from "../../utils/InputFieldOptions";
import {InputSelect} from "../shared/FormComponent/InputSelect";
import {IsLoading} from "../shared/IsLoading";
import {FormSkeleton} from "../shared/FormSkeleton";


export class CommonCreatePage extends React.Component{

    formRef = React.createRef();
    config = this.props.config;
    constructor(props) {
        super(props);
        console.log(props);

        this.state = {
            title: InputFields.title,
            status: InputFields.status
        };

        if(this.props.edit){
            const {id} = this.props.match.params;
            CrudBloc.instance.getDetails(id, this.config);
        }else{
            CrudBloc.instance.clearDetails(this.config.lower);
        }
    }

    componentDidMount() {
        const config = this.config;
        if(this.props.edit) {
            SettingsBloc.instance.setCurrentPageTitle(`${this.config.upper} Update`);
            const history = this.props.history;
            this.updateResponseSubscription = CrudBloc.instance.updateResponse.subscribe({
                next(x) {
                    if(x) {
                        history.push(getRouteList(config.lower));
                        CrudBloc.instance.clearUpdateResponseData();
                    }
                },
                error(err) { console.error('something wrong occurred: ' + err); },
                complete() { console.log('done'); }
            });
        } else {
            SettingsBloc.instance.setCurrentPageTitle(`Create new ${config.upper}`);
            const history = this.props.history;
            this.createResponseSubscription = CrudBloc.instance.createResponse.subscribe({
                next(x) {
                    if(x) {
                        history.push(getRouteList(config.lower));
                        CrudBloc.instance.clearCreateResponseData();
                    }
                },
                error(err) { console.error('something wrong occurred: ' + err); },
                complete() { console.log('done'); }
            });
        }
    }

    onFinish = (values) => {
        console.log('Success:', values);
        if(this.props.edit) {
            const {id} = this.props.match.params;
            CrudBloc.instance.update(id, values, this.config);
        } else {
            CrudBloc.instance.createNew(values, this.config);
        }
    };

    componentWillUnmount() {
        this.createResponseSubscription?.unsubscribe();
        this.updateResponseSubscription?.unsubscribe();
    }

    render() {
        return (
            <IsLoading name={this.config.lower}>
                <BlocBuilder
                    subject = {CrudBloc.instance[this.config.lower]}
                    builder = {(snapshot) => {
                        return   !this.props.edit ||  snapshot.data ? <Form ref={this.formRef} name="control-ref" onFinish={this.onFinish} layout="vertical" className="create-form">
                            <InputText values={this.state.title} value={this.props.edit && snapshot.data ? snapshot.data[this.state.title.name] : undefined}/>
                            <InputSelect
                                values={this.state.status}
                                options={InputFieldOptions.status}
                                value={this.props.edit && snapshot.data ? snapshot.data[this.state.status.name] : 1}
                            />
                            <Box y={10}/>
                            <Form.Item>
                                <ButtonX
                                    htmlType="submit"
                                    name="submit"
                                    text="Submit" className={"button-default-accent"}/>
                            </Form.Item>
                            <BlocBuilder
                                subject = {AuthBloc.instance.errorText}
                                builder = {(snapshot) => {
                                    return  <TextX text={snapshot.data} color={Colors.water_blue}/>
                                }}/>
                        </Form> : <FormSkeleton line={2}/>
                    }}/>
            </IsLoading>
        );
    }
}