import React from "react";
import {Col, Form, Row, Select} from "antd";
import "./FormComponent.css";
import {IsLoading} from "../IsLoading";
import {v4 as uuidv4} from 'uuid';

const {Option} = Select;

export class InputSelect extends React.Component {

  render() {

    const {value, isFetching, options, onSelect, isFilter, onClear, multiple = false, menuName = "", disabled = false} = this.props;
    const {name, label, placeholder, rules, prefixIcon, territory} = this.props.values;

    let multipleValues = [];
    if(multiple){
        if(value !== "NULL" && value !== undefined && value !== null && value !== "")
        multipleValues =  value.split(",");
    }
    return (
        <IsLoading name={menuName}>
      <Form.Item
          label={label}
          name={name}
          initialValue={value === undefined || value === null ? undefined : multiple ? multipleValues : `${value}`}
          rules={isFilter ? null : rules}>
          <Select
              autoComplete="none"
              mode={multiple ? "multiple": null}
              showSearch
              allowClear={true}
              placeholder={placeholder}
              size='large'
              loading={isFetching}
              onSelect={onSelect}
              onDeselect={onSelect}
              onClear={onClear ?? null}
              optionFilterProp="children"
              filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled  = {disabled}
              >
            {
                territory ?
                    options.map((e, i)=>
                        <Option key={e.id+ uuidv4()} value={e.id ? `${e.id}` : ""}>

                            <Col>
                                <Row>
                                    {e.title ? e.title : e.name ? e.name : 'Invalid Data' }
                                </Row>
                                <Row>
                                    {`Territory: ${e.territory.title}, Zone: ${e.territory.zone.title}`}
                                </Row>
                            </Col>

                        </Option>):
                options.map((e, i)=>
                    <Option key={e.id+ uuidv4()} value={e.id ? `${e.id}` : ""}>{e.title ? e.title : e.name ? e.name : 'Invalid Data' }
                    </Option>)
            }
          </Select>
      </Form.Item>
        </IsLoading>
    );
  }
}
