import React, {Component} from "react"
import {Route, Switch} from "react-router-dom"
import PropTypes from 'prop-types'
import {
    getRouteCreate,
    getRouteDetailsPath,
    getRouteList,
    getRouteUpdatePath,
    routeHome
} from "../../utils/RouterUtils";
import {HomePage} from "../home/HomePage";
import {UserCreatePage} from "../user/UserCreatePage";
import {MenuConfig} from "../../utils/Constants";
import {CommonListPage} from "../common/CommonListPage";
import {CommonDetailsPage} from "../common/CommonDetailsPage";
import {CommonCreatePage} from "../common/CommonCreatePage";
import {SafetyBehavioralQuestionCreatePage} from "../safety_behavioral_question/SafetyBehavioralQuestionCreatePage";
import {IncidentEventSubtypeCreatePage} from "../incident_event_subtype/IncidentEventSubtypeCreatePage";

class MainWindow extends Component {

    prepareListRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}List`}
                   location={this.props.location}
                   path={getRouteList(e.lower)}
                   render = {(props) => <CommonListPage config={e} {...props}  />} />)
    };

    prepareDetailsRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Details`}
                   location={this.props.location}
                   path={getRouteDetailsPath(e.lower)}
                   render = {(props) => <CommonDetailsPage config={e} {...props}  />} />)

    };

    prepareCreateRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Create`}
                   location={this.props.location}
                   path={getRouteCreate(e.lower)}
                   render = {(props) => <CommonCreatePage config={e} {...props}  />} />)

    };

    prepareEditRoute = (menuList) => {

        return  menuList.map(e =>
            <Route key={`${e.lower}Edit`}
                   location={this.props.location}
                   path={getRouteUpdatePath(e.lower)}
                   render = {(props) => <CommonCreatePage config={e} edit {...props}  />} />)

    };

    render() {
        const menuList = [
            MenuConfig.user, MenuConfig.user_group,
            MenuConfig.observation_type,
            MenuConfig.site, MenuConfig.location,
            MenuConfig.safety_behavioral_question,
            MenuConfig.hazard_type, MenuConfig.hazard_classification, MenuConfig.hazard_result,
            MenuConfig.incident_event_type, MenuConfig.incident_event_subtype,
            MenuConfig.incident_activity_type,

        ];
        const createMenuList = [MenuConfig.observation_type,
            MenuConfig.site, MenuConfig.location,
            MenuConfig.hazard_type, MenuConfig.hazard_classification, MenuConfig.hazard_result,
            MenuConfig.incident_event_type, MenuConfig.incident_activity_type, MenuConfig.user_group
        ];
        const location = this.props.location
        return (
           <div style={{overflowY:"auto", height:"100%"}}>
               <Switch>
                   <Route location={location} path={routeHome} component={HomePage} />

                   <Route location={location} path={getRouteCreate(MenuConfig.user.lower)} component={UserCreatePage}/>
                   <Route location={location} path={getRouteUpdatePath(MenuConfig.user.lower)} render = {(props) => <UserCreatePage edit {...props}  />} />

                   {/*<Route location={location} path={getRouteCreate(MenuConfig.user_group.lower)} component={UserGroupCreatePage}/>*/}
                   {/*<Route location={location} path={getRouteUpdatePath(MenuConfig.user_group.lower)} render = {(props) => <UserGroupCreatePage edit {...props}  />} />*/}

                   {this.prepareListRoute(menuList)}
                   {this.prepareEditRoute(createMenuList)}
                   {this.prepareCreateRoute(createMenuList)}


                   <Route key={`${MenuConfig.safety_behavioral_question.lower}Create`}
                          location={location}
                          path={getRouteCreate(MenuConfig.safety_behavioral_question.lower)}
                          component={SafetyBehavioralQuestionCreatePage}/>
                   <Route key={`${MenuConfig.safety_behavioral_question.lower}Edit`}
                          location={location}
                          path={getRouteUpdatePath(MenuConfig.safety_behavioral_question.lower)}
                          render = {(props) => <SafetyBehavioralQuestionCreatePage edit {...props}  />} />

                   <Route key={`${MenuConfig.incident_event_subtype.lower}Create`}
                          location={location}
                          path={getRouteCreate(MenuConfig.incident_event_subtype.lower)}
                          component={IncidentEventSubtypeCreatePage}/>
                   <Route key={`${MenuConfig.incident_event_subtype.lower}Edit`}
                          location={location}
                          path={getRouteUpdatePath(MenuConfig.incident_event_subtype.lower)}
                          render = {(props) => <IncidentEventSubtypeCreatePage edit {...props}  />} />

                   {this.prepareDetailsRoute(menuList)}






                   {/*//_add_from_here*/}
                   <Route location={location} component={HomePage} />
               </Switch>
           </div>

        )
    }
}

MainWindow.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
}

export default MainWindow