import logo from '../images/logo.png';
import logo_unilever from '../images/logo_unilever.png'
import pending from '../images/pending.gif'
import total from '../images/total.gif'
import solved from '../images/solved.gif'
import exceeded from '../images/exceeded.gif'

const Images = {
  logo: logo,
  logoUnilever: logo_unilever,
  pending: pending,
  exceeded: exceeded,
  solved: solved,
  total: total

};

export default Images;



