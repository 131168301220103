import {Link} from 'react-router-dom'
import React, {Component} from 'react';
import './LeftSideBar.css';
import {getRouteList, routeHome} from '../../../utils/RouterUtils';
import {Layout, Menu} from "antd";
import {HomeOutlined, LogoutOutlined, UserOutlined} from "@ant-design/icons";

import SettingsBloc from "../../../bloc/SettingsBloc";
import AuthBloc from "../../../bloc/AuthBloc";
import {MenuConfig} from "../../../utils/Constants";
import Images from "../../../utils/Images";
import BlocBuilder from "../../../BlocBuilder";

const { SubMenu } = Menu;
const { Sider } = Layout;


class LeftSideBar extends Component {

    constructor(props) {
        super(props);
        this.setMenuAndRoutes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setMenuAndRoutes();
    }

    setMenuAndRoutes = () => {
        const path = this.props.location.pathname;
        Object.keys(routes).forEach(function(key, _idx) {
            if(path.split("/")[1] === (routes[key].key)){
                SettingsBloc.instance.setCurrentRoute(routes[key]);
                SettingsBloc.instance.setCurrentMenu(menus[routes[key].menu]);
            }
        });
    }

    onOpenChange = keys => {
        SettingsBloc.instance.setCurrentMenu(menus[keys[1]])
    };

    render() {
        console.log(AuthBloc.instance.getUser())
        return (
            <BlocBuilder
                subject={SettingsBloc.instance.sideBarCollapsed}
                builder={(snapshot) => {
                    return <BlocBuilder
                        subject={SettingsBloc.instance.currentRoute}
                        builder={(snapshotRoute) => {
                            // console.log(snapshotRoute.data)
                            return <BlocBuilder
                                subject={SettingsBloc.instance.currentMenu}
                                builder={(snapshotMenu) => {
                                    // console.log(snapshotMenu.data)
                                    return <Sider trigger={null} collapsible collapsed={snapshot.data}
                                                  style={{background: "#86ACD7"}}>
                                        <Link key={"home-link"} to={"/dashboard"}>
                                            <div className="side-logo">

                                                <img
                                                    style={{marginRight: 32, filter: "brightness(0) invert(1)"}}
                                                    width={40}
                                                    src={Images.logoUnilever}
                                                />
                                                <img
                                                    width={80}
                                                    src={Images.logo}
                                                />
                                            </div>
                                        </Link>
                                        <Menu
                                            style={{background: "#86ACD7"}}

                                            mode="inline"
                                            selectedKeys={[snapshotRoute?.data?.key]}
                                            defaultSelectedKeys={[snapshotRoute?.data?.key]}
                                            defaultOpenKeys={[snapshotRoute?.data?.menu]}
                                            openKeys={[snapshotMenu?.data?.key]}
                                            onOpenChange={this.onOpenChange}
                                        >
                                            {this.prepareMenu(routes.home)}
                                            {/*{AuthBloc.instance.isEditor() && this.prepareMenu(routes.complaint_create)}*/}
                                            {/*{this.prepareMenu(routes.complaint)}*/}
                                            {/*{AuthBloc.instance.isEditor() && this.prepareMenuWithSubMenu(menus.payouts)}*/}
                                            {/*{AuthBloc.instance.isEditor() && this.prepareMenu(routes.outlet)}*/}
                                            {this.prepareMenuWithSubMenu(menus.users)}
                                            {/*{this.prepareMenu(routes.report)}*/}
                                            {/*{this.prepareMenu(routes.visit)}*/}
                                            {/*{this.prepareMenu(routes.order)}*/}
                                            {this.prepareMenuWithSubMenu(menus.locations)}
                                            {/*{this.prepareMenuWithSubMenu(menus.managers)}*/}
                                            {this.prepareMenuWithSubMenu(menus.observation)}
                                            {this.prepareMenuWithSubMenu(menus.hazard)}
                                            {this.prepareMenuWithSubMenu(menus.incident)}
                                            {/*{AuthBloc.instance.isEditor() && this.prepareMenu(routes.outbound_call)}*/}

                                            <Menu.Item key={"logout"}  style={{color: "#000000"}} icon={<LogoutOutlined/>}
                                                       onClick={() => AuthBloc.instance.logout()}>
                                                {"Logout"}
                                            </Menu.Item>
                                        </Menu>
                                    </Sider>
                                }}/>
                        }}/>
                }}/>
        )
    }

    prepareSubMenu = (item) => {
        return <Menu.Item key={item?.key} style={{background: "#91c4cb", color: "#000000"}}>
            <Link key={item?.key+"-link"} to={item?.to} style={{color: "#000000"}}>{item?.label}</Link>
        </Menu.Item>
    };

    prepareMenu = (item) => {
        return <Menu.Item key={item?.key} icon={item.icon} style={{background: "#91c4cb", color: "#000000"}}>
            <Link key={item?.key+"-link"} to={item?.to} style={{color: "#000000"}}>{item?.label}</Link>
        </Menu.Item>
    };

    prepareMenuWithSubMenu = (item) => {
        return  <SubMenu key={item?.key} icon={item.icon} title={item.label} style={{background: "#91c4cb", color: "#000000"}}>
            {
                item.submenu.map(e => this.prepareSubMenu(e))
            }
        </SubMenu>
    };
}

export default LeftSideBar;
const routes = {
    home: {key: MenuConfig.home.lower,
        label: MenuConfig.home.upper,
        to: routeHome,
        icon: <HomeOutlined />},

    user: {key: MenuConfig.user.lower,
        label: MenuConfig.user.upper,
        to: getRouteList(MenuConfig.user.lower),
        icon: <HomeOutlined />,
        menu: "users"},
    user_group: {key: MenuConfig.user_group.lower,
        label: MenuConfig.user_group.upper,
        to: getRouteList(MenuConfig.user_group.lower),
        icon: <HomeOutlined />,
        menu: "users"},


    observation_type: {
        key: MenuConfig.observation_type.lower,
        label: MenuConfig.observation_type.upper,
        to: getRouteList(MenuConfig.observation_type.lower),
        icon: <HomeOutlined />,
        menu: "observation"},

    safety_behavioral_question: {
        key: MenuConfig.safety_behavioral_question.lower,
        label: MenuConfig.safety_behavioral_question.upper,
        to: getRouteList(MenuConfig.safety_behavioral_question.lower),
        icon: <HomeOutlined />,
        menu: "observation"},

    hazard_type: {
        key: MenuConfig.hazard_type.lower,
        label: MenuConfig.hazard_type.upper,
        to: getRouteList(MenuConfig.hazard_type.lower),
        icon: <HomeOutlined />,
        menu: "hazard"},

    hazard_classification: {
        key: MenuConfig.hazard_classification.lower,
        label: MenuConfig.hazard_classification.upper,
        to: getRouteList(MenuConfig.hazard_classification.lower),
        icon: <HomeOutlined />,
        menu: "hazard"},

    hazard_result: {
        key: MenuConfig.hazard_result.lower,
        label: MenuConfig.hazard_result.upper,
        to: getRouteList(MenuConfig.hazard_result.lower),
        icon: <HomeOutlined />,
        menu: "hazard"},

    incident_event_type: {
        key: MenuConfig.incident_event_type.lower,
        label: MenuConfig.incident_event_type.upper,
        to: getRouteList(MenuConfig.incident_event_type.lower),
        icon: <HomeOutlined />,
        menu: "incident"},

    incident_event_subtype: {
        key: MenuConfig.incident_event_subtype.lower,
        label: MenuConfig.incident_event_subtype.upper,
        to: getRouteList(MenuConfig.incident_event_subtype.lower),
        icon: <HomeOutlined />,
        menu: "incident"},

    incident_activity_type: {
        key: MenuConfig.incident_activity_type.lower,
        label: MenuConfig.incident_activity_type.upper,
        to: getRouteList(MenuConfig.incident_activity_type.lower),
        icon: <HomeOutlined />,
        menu: "incident"},

    site: {
        key: MenuConfig.site.lower,
        label: MenuConfig.site.upper,
        to: getRouteList(MenuConfig.site.lower),
        icon: <HomeOutlined />,
        menu: "locations"},

    location: {
        key: MenuConfig.location.lower,
        label: MenuConfig.location.upper,
        to: getRouteList(MenuConfig.location.lower),
        icon: <HomeOutlined />,
        menu: "locations"},
}
const menus = {
    users: {
        key: "users",
        label: "User",
        icon: <UserOutlined />,
        submenu: [routes.user, routes.user_group]},
    // managers: {
    //     key: "managers",
    //     label: "Managers",
    //     icon: <UserOutlined />,
    //     submenu: [routes.line_manager, routes.field_force]},
    locations: {
        key: "locations",
        label: "Locations",
        icon: <UserOutlined />,
        submenu: [routes.site, routes.location]},

    observation: {
        key: "observation",
        label: "Safety Observation",
        icon: <UserOutlined />,
        submenu: [routes.observation_type, routes.safety_behavioral_question]},

    hazard: {
        key: "hazard",
        label: "Hazard Items",
        icon: <UserOutlined />,
        submenu: [routes.hazard_type, routes.hazard_classification, routes.hazard_result, ]},

    incident: {
        key: "incident",
        label: "Incident Items",
        icon: <UserOutlined />,
        submenu: [routes.incident_event_type, routes.incident_event_subtype, routes.incident_activity_type,]},

}
