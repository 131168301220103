import {observationTypeConstants,} from "../components/observation_type/ObservationTypeConstants";
import {userGroupConstants} from "../components/user_group/UserGroupConstants";
import {userConstants} from "../components/user/UserConstants";
import {siteConstants} from "../components/site/SiteConstants";
import {hazardTypeConstants} from "../components/hazard_type/HazardTypeConstants";
import {
    safetyBehavioralQuestionConstants
} from "../components/safety_behavioral_question/SafetyBehavioralQuestionConstants";
import {locationConstants} from "../components/location/LocationConstants";
import {hazardClassificationConstants} from "../components/hazard_classification/HazardClassificationConstants";
import {hazardResultConstants} from "../components/hazard_result/HazardResultConstants";
import {incidentEventTypeConstants} from "../components/incident_event_type/IncidentEventTypeConstants";
import {incidentEventSubtypeConstants} from "../components/incident_event_subtype/IncidentEventSubtypeConstants";
import {incidentActivityTypeConstants} from "../components/incident_activity_type/IncidentActivityTypeConstants";


export const BASEURL = process.env.REACT_APP_BASE_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const APP_NAME = process.env.REACT_APP_NAME;

export const SKIP = 10;
export const LIMIT = 10;

export const MenuConfig = {
    home: { lower: "dashboard", upper: "Home"},

    user: {
        lower: "user",
        upper: "User",
        api: "users",
        listTitle: "User List",
        filter: userConstants.filters,
        list: userConstants.listAttributes,
        details: userConstants.detailsAttributes,
        stream: "userList"
    },
    user_group: {
        lower: "user_group",
        upper: "UserGroup",
        api: "user-groups",
        listTitle: "UserGroup List",
        filter: userGroupConstants.filters,
        list: userGroupConstants.listAttributes,
        details: userGroupConstants.detailsAttributes,
        stream: "user_groupList"
    },

    observation_type: {
        lower: "observation_type",
        upper: "ObservationType",
        listTitle: "Observation Type List",
        api: "observation-types",
        filter: observationTypeConstants.filters,
        list: observationTypeConstants.listAttributes,
        details: observationTypeConstants.detailsAttributes,
        stream: "observation_typeList"
    },

    site: {
        lower: "site",
        upper: "Site",
        listTitle: "Site List",
        api: "sites",
        filter: siteConstants.filters,
        list: siteConstants.listAttributes,
        details: siteConstants.detailsAttributes,
        stream: "siteList"
    },

    location: {
        lower: "location",
        upper: "Location",
        listTitle: "Location List",
        api: "locations",
        filter: locationConstants.filters,
        list: locationConstants.listAttributes,
        details: locationConstants.detailsAttributes,
        stream: "locationList"
    },

    safety_behavioral_question: {
        lower: "safety_behavioral_question",
        upper: "SafetyBehavioralQuestion",
        listTitle: "SafetyBehavioralQuestion List",
        api: "safety-behavioral-questions",
        filter: safetyBehavioralQuestionConstants.filters,
        list: safetyBehavioralQuestionConstants.listAttributes,
        details: safetyBehavioralQuestionConstants.detailsAttributes,
        stream: "safety_behavioral_questionList"
    },

    hazard_type: {
        lower: "hazard_type",
        upper: "HazardType",
        listTitle: "HazardType List",
        api: "hazard-types",
        filter: hazardTypeConstants.filters,
        list: hazardTypeConstants.listAttributes,
        details: hazardTypeConstants.detailsAttributes,
        stream: "hazard_typeList"
    },

    hazard_classification: {
        lower: "hazard_classification",
        upper: "HazardClassification",
        listTitle: "HazardClassification List",
        api: "hazard-classifications",
        filter: hazardClassificationConstants.filters,
        list: hazardClassificationConstants.listAttributes,
        details: hazardClassificationConstants.detailsAttributes,
        stream: "hazard_classificationList"
    },

    hazard_result: {
        lower: "hazard_result",
        upper: "HazardResult",
        listTitle: "HazardResult List",
        api: "hazard-results",
        filter: hazardResultConstants.filters,
        list: hazardResultConstants.listAttributes,
        details: hazardResultConstants.detailsAttributes,
        stream: "hazard_resultList"
    },

    incident_event_type: {
        lower: "incident_event_type",
        upper: "IncidentEventType",
        listTitle: "IncidentEventType List",
        api: "incident-event-types",
        filter: incidentEventTypeConstants.filters,
        list: incidentEventTypeConstants.listAttributes,
        details: incidentEventTypeConstants.detailsAttributes,
        stream: "incident_event_typeList"
    },

    incident_event_subtype: {
        lower: "incident_event_subtype",
        upper: "IncidentEventSubtype",
        listTitle: "IncidentEventSubtype List",
        api: "incident-event-subtypes",
        filter: incidentEventSubtypeConstants.filters,
        list: incidentEventSubtypeConstants.listAttributes,
        details: incidentEventSubtypeConstants.detailsAttributes,
        stream: "incident_event_subtypeList"
    },

    incident_activity_type: {
        lower: "incident_activity_type",
        upper: "IncidentActivityType",
        listTitle: "IncidentActivityType List",
        api: "incident-activity-types",
        filter: incidentActivityTypeConstants.filters,
        list: incidentActivityTypeConstants.listAttributes,
        details: incidentActivityTypeConstants.detailsAttributes,
        stream: "incident_activity_typeList"
    },

}

