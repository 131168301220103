export const InputFieldOptions = {
    status: [
        {id: 1, title: "Active"},
        {id: 2, title: "In-Active"},
    ],

    class: [
        {id: 1, title: "A"},
        {id: 2, title: "B"},
    ],

    pronounce_title:[
        {id: 1, title: "Dr"},
        {id: 2, title: "Mr"},
        {id: 3, title: "Mrs"},
    ],

    complaint_status: [
        {id: 1, title: "Pending"},
        {id: 2, title: "Re-Opened"},
        {id: 3, title: "Resolved"},
        {id: 4, title: "Expired"},
        {id: 5, title: "Escalated"},
    ],
    sticker_status: [
        {id: 1, title: "Yes"},
        {id: 2, title: "No"},
    ],


    is_action_taken: [
        {id: 1, title: "Yes"},
        {id: 2, title: "No"},
    ],

    distributor_type_id: [
        {id: 1, title: "UBL"},
        {id: 2, title: "UCL"},
        {id: 3, title: "Common"},
    ],

    program: [
        {id: "program_dosti", title: "Dosti"},
        {id: "program_perfect_store", title: "Perfect Store"},
        {id: "program_top_gun", title: "Top Gun"},
    ],

    year: [
        {id: 2018, title: "2018"},
        {id: 2019, title: "2019"},
        {id: 2020, title: "2020"},
        {id: 2021, title: "2021"},
        {id: 2022, title: "2022"},
        // {id: 3, title: "2022"},
    ]
}

