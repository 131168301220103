import * as rxjs from "rxjs";
import axiosWrapper from "../utils/AxiosWrapper";
import {BASEURL, MenuConfig} from "../utils/Constants";
import _ from 'lodash';
import {prepareFilterParamsWithDate, prepareUrlWithFilter} from "../utils/Utils";
import {
    getApiCreate,
    getApiDetails,
    getApiList,
    getApiUpdate,
    getRouteDetails,
    getRouteUpdate
} from "../utils/RouterUtils";
import ErrorBloc from "./ErrorBloc";
import AuthBloc from "./AuthBloc";
import LoadingBloc from "./LoadingBloc";
import {getItem, isItemExist, saveItem} from "../utils/LocalStorage";
import moment from "moment";

export default class CrudBloc {

    static instance = new CrudBloc();

    constructor(){

        for (const key of Object.keys(MenuConfig)) {
            //console.log(key + " -> " + MenuNames[key])
            this[`${key}List`] = new rxjs.BehaviorSubject({list:[], total: 0});
            this[`${key}`] = new rxjs.BehaviorSubject(null);
        }

        this.createResponse = new rxjs.BehaviorSubject(null);
        this.updateResponse = new rxjs.BehaviorSubject(null);

    }

    getDetails(id, config){
        const name = config.lower;
        const api = config.api;

        LoadingBloc.instance.start(name);
        this.clearDetails(name);
        axiosWrapper
            .get(BASEURL+ getApiDetails(api, id))
            .then((response) => {
                this.prepareDetails(response, name);
                LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    updateFields = (e, name) => {
        switch (name){
            case MenuConfig.incident_event_subtype.lower:

                e.incident_event_type_title = e.incident_event_type?.title;
                break;
            // case MenuConfig.field_force.lower:
            //     e.territory_title = e.territory?.title;
            //     e.zone_title = e.territory?.zone?.title;
            //     e.line_manager_name = e.territory?.line_manager?.name;
            //     break;
            // case MenuConfig.territory.lower:
            //     e.zone_title = e.zone?.title;
            //
            //     break;
            // case MenuConfig.healthcare_professional.lower:
            //
            //     e.class_title = ["","A","B","C"][e.class_id ?? 0];
            //     e.designation_title = e.designation?.title;
            //     e.category_title = e.category?.title;
            //     e.institution_title = e.institution?.name;
            //     break;
            case MenuConfig.user.lower:
                e.user_group_title = e.user_group?.title;
                break;
            // case MenuConfig.chemist.lower:
            //     e.institution_title = e.institution?.name;
            //     break;
            //
            // case MenuConfig.visit.lower:
            //     e.type = e?.visit_type === 1 ? "HCP" : "Chemist";
            //     e.check_in_at = e.check_in?.created_at;
            //     e.field_force_name = e.field_force?.name;
            //     break;
            // case MenuConfig.report.lower:
            //     e.visit_type = e?.visit?.visit_type === 1 ? "HCP" : "Chemist";
            //     e.check_in_at = e?.visit?.check_in?.created_at;
            //     e.field_force_name = e.field_force?.name;
            //     break;
            default:

                break;
        }
        return e;
    }

    prepareDetails = (response, name) => {
        let e = response.data.data;
        e = this.updateFields(e, name);
        this[name].next(e)
    }

    canSavedOnLocal = (name) => {
        switch (name){
            case MenuConfig.incident_event_type.lower:
                return true;
            default:
                return false;
        }
    }

    getList(search, config, dateRange){

        const name = config.lower;
        const api = config.api;
        const stream = config.stream;
        const filterParams = config.filter;
        LoadingBloc.instance.start(name);
        let localData = false;
        if(search?.limit === 100 && this.canSavedOnLocal(name)){
            if(isItemExist(name, 60)){
                let data = getItem(name);
                let list = _.cloneDeep(data.data);
                this[stream].next({list: this.prepareList(list, name), total: data.count});
                localData = true;
            }
        }

        if(localData) {
            LoadingBloc.instance.end(name);
            return;
        }

        let filter = prepareUrlWithFilter(search, filterParams)
        if(dateRange){
            filter = prepareFilterParamsWithDate(filter, dateRange);
        }
        axiosWrapper
            .get(BASEURL+ getApiList(api, filter))
            .then((response) => {
                saveItem(name, response.data, moment());
                let list = _.cloneDeep(response.data.data);
                this[`${name}List`].next({list: this.prepareList(list, name), total: response.data.count})
                LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    prepareList = (list, name, action = ["View", "Edit"]) => {
        return list && Array.isArray(list)? list.map((e) => {
            e.detailsPath = getRouteDetails(name, e.id);
            e = this.updateFields(e, name);
            e.actions = [];
            action.forEach(action =>{
                switch(action){
                    case "View":
                        e.actions.push({
                            url: getRouteDetails(name, e.id),
                            title: "View",
                        })
                        break;
                    case "Edit":
                        e.actions.push({
                            url: getRouteUpdate(name, e.id),
                            title: "Edit",
                        })
                        break;

                }
            });
            if(AuthBloc.instance.isManager()){
                e.actions = e.actions.slice(0,2)
            }
            else if(AuthBloc.instance.isAdmin() === false){
                e.actions = e.actions.slice(0,1)
            }
            return e;
        }) : [];
    }

    createNew(values, config) {
        const name = config.lower;
        const api = config.api;

        LoadingBloc.instance.start(name);
        ErrorBloc.instance.error(null);
        axiosWrapper
            .post(BASEURL+ getApiCreate(api), values)
            .then((response) => {
                this.createResponse.next(response.data.data);
                LoadingBloc.instance.end(name);
            })
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });

    }
    update(id, values, config) {
        const name = config.lower;
        const api = config.api;
        LoadingBloc.instance.start(name);
        ErrorBloc.instance.error(null);
        axiosWrapper
            .put(BASEURL+ getApiUpdate(api, id), values)
            .then((response) => {
                this[name].next(response.data.data);
                this.updateResponse.next(response.data.data);
                LoadingBloc.instance.end(name);
            },)
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    updatePost(id, values, name) {
        LoadingBloc.instance.start(name);
        ErrorBloc.instance.error(null);
        axiosWrapper
            .post(BASEURL+ getApiUpdate(name, id), values)
            .then((response) => {
                //this[name].next(response.data.data);
                this.updateResponse.next(response.data.data);
                LoadingBloc.instance.end(name);
            },)
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    updatePassword(id, values, name) {
        LoadingBloc.instance.start(name);
        ErrorBloc.instance.error(null);
        axiosWrapper
            .put(BASEURL+ `/user/update_password/${id}`, values)
            .then((response) => {
                this[name].next(response.data.data);
                this.updateResponse.next(response.data.data);
                LoadingBloc.instance.end(name);
            },)
            .catch((error) => {
                ErrorBloc.instance.error(error);
                LoadingBloc.instance.end(name);
            });
    }

    clearDetails(name) {
        this[name].next(null);
    }

    clearCreateResponseData(){
        this.createResponse.next(null);
    }
    clearUpdateResponseData(){
        this.updateResponse.next(null);
    }

    clearList(name) {
        this[`${name}List`].next({list: [], total: 0})
    }

    clearLists(names) {
        names.forEach(name => this[`${name}List`].next({list: [], total: 0}))
    }
}